import type { Environment } from '@change-corgi/config/environments';

import __mediaAssets from '../config/mediaAssets.json';

type MediaAssetsBasePath = Readonly<{
	view: Readonly<Record<Exclude<Environment, 'development'>, string>>;
	edit: Readonly<Record<Exclude<Environment, 'development'>, string>>;
}>;
const mediaAssetBasePath = __mediaAssets.basePaths as MediaAssetsBasePath;

export function getMediaAssetsBasePath({
	environment,
	mode,
}: {
	environment: Exclude<Environment, 'development'>;
	mode: 'edit' | 'view';
}): string {
	return mediaAssetBasePath[mode][environment];
}
